import React, { useState, useRef, useEffect } from 'react';
import BarraPrincipal from '../Modulos/BarraPrincipal';
import Swal from 'sweetalert2';
import SwitchButton2 from '../componentes/SwithButton2';
import axios from '../utils/axiosConfig';
import SwitchButton3 from '../componentes/SwitchButton3';


const CrearContrato2 = ({onClose}) => {
    const [pedido, setPedido] = useState('');
    const [dispositivos, setDispositivos] = useState([]);
    const [tipoServicios, setTipoServicios] = useState([]);
    const [selectedDispositivos, setSelectedDispositivos] = useState([]);
    const [ubicacion, setUbicacion] = useState('');
    const [fechaHoraInicio, setFechaHoraInicio] = useState('');
    const [selectedTipoServicio, setSelectedTipoServicio] = useState();
    const [loading, setLoading] = useState(false);

    const handleSwitchChange = (dispositivo, isActive) => {
        if (isActive && !selectedDispositivos.includes(dispositivo) && selectedDispositivos.length >= 5) {
            Swal.fire({
                icon: 'error',
                title: 'Límite alcanzado',
                text: 'No puedes seleccionar más de 5 dispositivos.',
            });
            return;
        }

        setSelectedDispositivos((prev) =>
            isActive
                ? [...prev, dispositivo] // Agrega el dispositivo si está activo
                : prev.filter((item) => item !== dispositivo) // Elimina el dispositivo si no está activo
        );
    };

    // Deshabilitar switches adicionales
    const isDisabled = selectedDispositivos.length >= 5;


    const handleChange = (e) => {
        const selectedId = e.target.value; // Obtén el valor seleccionado (que sería el ID)
        console.log('ID seleccionado:', selectedId);
        setSelectedTipoServicio(parseInt(selectedId)); // Actualiza el estado con el ID
        getDispositivosByTipoServicio(selectedId);
    };

    const formatContenedoresToString = (dispositivos) => {
        if (!dispositivos || dispositivos.length === 0) return '';
        return dispositivos.join(','); // Une los elementos del array con comas
    };

    const cleanFields = () => {
        setPedido('');
        setDispositivos([]);
        setTipoServicios([]);
        setSelectedDispositivos([]);
        setUbicacion('');
        setFechaHoraInicio('');
        setSelectedTipoServicio();
    };


    const [dates, setDates] = useState({ fechaHoraCita: '' });

    const refs = {
        fechaHoraCita: useRef(null),
    };

    const onSubmit = async (data) => {
        await axios
            .post(process.env.REACT_APP_SERVER + "/contratos/crearcontrato", JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    console.log(res.data.mensaje);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    cleanFields();
                    Swal.fire({
                        icon: "success",
                        title: "Contrato creado con éxito",
                        text: "Contrato cargado con éxito",
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#006699';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });

                }
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Error al crear el contrato",
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            });
    };

    const handleGuardar = async () => {
        if (!selectedTipoServicio || selectedDispositivos.length === 0 || !ubicacion || !dates.fechaHoraCita) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Todos los campos deben estar llenos.',
            });
            return;
        }

        setLoading(true);
        const format = formatContenedoresToString(selectedDispositivos);
        let data = {
            tipoequipo: selectedTipoServicio,
            ubicacion: ubicacion,
            fechahora: dates.fechaHoraCita,
            listaequipo: format,
        };
        console.log('Data:', data);
        await onSubmit(data);
        setLoading(false);
    };

    const getTypeServices = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_SERVER + "/contratos/getlistatiposequipo", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,

            });

            if (res.data.success) {
                console.log('Tipo de servicios:', res.data.data);
                setTipoServicios(res.data.data);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo obtener la información, error de conexión',
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            console.error('Error al obtener tipos de servicios:', error);
            Swal.fire({
                title: 'Error',
                text: `Error: ${error.message}`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    }

    const getDispositivosByTipoServicio = async (idTipoServicio) => {
        let data = {
            "tipoequipo": idTipoServicio
        }
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_SERVER}/contratos/getlistadispositivosdisponibles`, JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('tkn')}`,
                    },
                }
            );

            if (res.data.success) {
                console.log('Dispositivos:', res.data.data);
                setDispositivos(res.data.data);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo obtener la información, error de conexión',
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            console.error('Error al obtener dispositivos:', error);
            Swal.fire({
                title: 'Error',
                text: `Error: ${error.message}`,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (refs.fechaHoraCita.current) {
                const now = new Date();

                $.datetimepicker.setLocale('en');
                $(refs.fechaHoraCita.current).datetimepicker({
                    format: 'Y-m-d H:i',
                    step: 30,
                    value: now.toISOString().slice(0, 16).replace('T', ' '), // Fecha/hora inicial
                    timepicker: true,
                    datepicker: true,
                    maxDate: now, // Establece la fecha máxima permitida como la actual
                    onChangeDateTime: (dp, $input) => {
                        const selectedDate = new Date($input.val());
                        if (selectedDate > now) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Fecha inválida',
                                text: 'La fecha/hora seleccionada no puede ser mayor a la actual.',
                                confirmButtonColor: '#006699',
                            });
                            $input.val(''); // Limpia el campo si la fecha/hora es inválida
                            setDates((prevState) => ({
                                ...prevState,
                                fechaHoraCita: '', // Resetea el estado
                            }));
                        } else {
                            setDates((prevState) => ({
                                ...prevState,
                                fechaHoraCita: $input.val(), // Actualiza el estado con la fecha válida
                            }));
                        }
                    },
                });
            }
        }, 100); // Retraso para asegurar que el DOM esté completamente cargado

        return () => {
            if (refs.fechaHoraCita.current) {
                $(refs.fechaHoraCita.current).datetimepicker('destroy');
            }
            clearTimeout(timer);
        };
    }, [refs.fechaHoraCita]);


    useEffect(() => {
        getTypeServices();
    }, []);

    useEffect(() => {
        if (selectedTipoServicio) {
            console.log('ID Tipo Servicio:', selectedTipoServicio);
        }
    }, [selectedTipoServicio]);

    useEffect(() => {
        if (selectedDispositivos.length > 0) {
            console.log('Dispositivos:', selectedDispositivos);
        }
    }, [selectedDispositivos]);

    return (
        <>


            <form>
                {/* Select para Tipo Servicios */}
                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="pedido" style={{ display: 'block', marginBottom: '8px' }}>
                        Tipo Servicios:
                    </label>
                    <select
                        className='custom-select'
                        id="pedido"
                        value={selectedTipoServicio}
                        onChange={handleChange}
                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                    >
                        <option value="">Selecciona un tipo de servicio</option>
                        {tipoServicios && tipoServicios.map((tipoServicio) => (
                            <option key={tipoServicio.IDTipoServicios} value={tipoServicio.IDTipoServicios}>{tipoServicio.Descripcion}</option>
                        ))}
                    </select>
                </div>

                {/* Switch Buttons para dispositivos */}
                {dispositivos && dispositivos.length > 0 && (
                    <div style={{ marginBottom: '16px' }}>
                        <label style={{ display: 'block', marginBottom: '8px' }}>Dispositivos:</label>
                        <div
                            style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                border: '1px solid #ccc',
                                padding: '8px',
                                borderRadius: '4px',
                            }}
                        >
                            {dispositivos.map((dispositivo) => (
                                <div key={dispositivo.DeviceID} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <SwitchButton3
                                        checked={selectedDispositivos.includes(dispositivo.DeviceID)}
                                        onChange={(isActive) => handleSwitchChange(dispositivo.DeviceID, isActive)}
                                        type={dispositivo.DeviceID}
                                        disabled={
                                            !selectedDispositivos.includes(dispositivo.DeviceID) && selectedDispositivos.length >= 5
                                        }
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                )}

                {/* Ubicacion */}
                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="ubicacion" style={{ display: 'block', marginBottom: '8px' }}>
                        Ubicación:
                    </label>
                    <input
                        type="text"
                        id="ubicacion"
                        value={ubicacion}
                        onChange={(e) => setUbicacion(e.target.value)}
                        style={{ width: '96%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                    />
                </div>

                {/* Fecha/Hora Inicio */}
                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="fechaInicio" style={{ display: 'block', marginBottom: '8px' }}>Fecha/Hora Inicio:</label>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {/* Aplicar manualmente las clases de Reactstrap */}
                        <input
                            ref={refs.fechaHoraCita}
                            type="text"
                            value={dates.fechaHoraCita || ''}
                            onChange={(e) => setDates({ ...dates, fechaHoraCita: e.target.value })}
                            autoComplete="off"
                            className="form-control" // Clase principal para estilo de Bootstrap
                            style={{
                                width: '100%',
                                border: '1px solid #ced4da',
                                borderRadius: '.25rem',
                                padding: '.375rem .75rem',
                                fontSize: '1rem',
                                lineHeight: '1.5',
                            }}
                        />
                    </div>
                </div>

                {/* Botón Guardar */}
                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <button
                        type="button"
                        onClick={handleGuardar}
                        disabled={!selectedTipoServicio || selectedDispositivos.length === 0 || !ubicacion || !dates.fechaHoraCita || loading}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: loading ? '#ccc' : '#006699',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: loading ? 'not-allowed' : 'pointer',
                            fontSize: '16px',
                            marginRight: '10px',
                            position: 'relative',
                        }}
                    >
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '8px' }}
                            ></span>
                        ) : null}
                        Guardar
                    </button>

                </div>
            </form>
        </>
    );
};

export default CrearContrato2;
