import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../Pages/Css/reportes.css';
import Swal from 'sweetalert2';
import { FaCompress } from '@react-icons/all-files/fa/FaCompress';
import { FaExpand } from '@react-icons/all-files/fa/FaExpand';
import axios from '../utils/axiosConfig';
var miStorage = window.localStorage;
export default function ReporteTabla(props) {
  const [data, setData] = useState([]);
  const [embbedData, setEmbbedData] = useState(null);
  const [embeddedURL, setEmbeddedURL] = useState();
  const [tokenPBI, setTokenPBI] = useState();
  const project = localStorage.getItem("project");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const cardBodyRef = useRef(null);
  const powerBIEmbedRef = useRef(null);

  useEffect(() => {
    console.log(props.onActualizarTabla);
    console.log(props.idPowerBi);
    console.log(process.env.REACT_APP_AZURE_ORGANIZATIONID);
    getTokenMicrosoftPBI();

  }, [props.onActualizarTabla, props.idPowerBi]);


  useEffect(() => {
    console.log(embbedData);
  }, [embbedData]);

  const getTokenMicrosoftPBI = () => {
    console.log("cargando acceso...");
    axios.get(process.env.REACT_APP_SERVER + '/token', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
    }).then((res) => {
      console.log("Token info de microsoft access", res);
      setTokenPBI(res.data.token);
      loadReportPBI();
    }).catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error al obtener el acceso!"
      });
    })
  }

  const loadReportPBI = () => {
    if (props.idPowerBi && tokenPBI) {
      console.log("Cargando reporte...", tokenPBI);
      console.log(process.env.REACT_APP_AZURE_ORGANIZATIONID);
      console.log(props.idPowerBi);
      console.log(`https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_AZURE_ORGANIZATIONID}/reports/${props.idPowerBi}`);
      // axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_AZURE_ORGANIZATIONID}/reports/${props.idPowerBi}`, {
      //   headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenPBI}` },
      // }).then((res) => {
      //   console.log("Respuesta de codigo embedded: ", res);
      //   setEmbeddedURL(res.data.embedUrl);
      // }).catch((error) => {
      //   console.log(error);
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Error al obtener el codigo embebido!"
      //   });
      // })
      fetch(
        `https://api.powerbi.com/v1.0/myorg/groups/${process.env.REACT_APP_AZURE_ORGANIZATIONID}/reports/${props.idPowerBi}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenPBI}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          console.log("Report data:", data);
          console.log("Report data:", data.embedUrl);
          setEmbeddedURL(data.embedUrl);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error al obtener el codigo embebido!"
          });
        });

    }
  }

  const toggleFullscreen = () => {
    const element = cardBodyRef.current;


    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }



    setIsFullscreen(!isFullscreen);
  };

  const handleDownload = async () => {
    // Hacer una petición al servidor para obtener el archivo
    const json = JSON.stringify(data);
    console.log(json);
    axios.post(process.env.REACT_APP_SERVER + '/editar-excel', json, {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true, responseType: 'blob'
    })
      .then(res => {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Convertir la respuesta en un blob

        // Crear un enlace para descargar el archivo
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'archivo.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })

  };


  return (
    <>
      {/* <div>
        <button onClick={handleDownload} class="btn btn-primary"><i className="bx bx-download me-1"></i> Exportar reporte</button>
      </div> */}
      {/* <DataTableExtensions
        {...tableData} export={false} print={false}
      >
        <DataTable
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          highlightOnHover
          export={false}
          onRowClicked={handleRowClicked}
        />

      </DataTableExtensions> */}
      <div className='card' ref={cardBodyRef}>
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={toggleFullscreen}
          style={{width: "100%"}}
        >
          {isFullscreen ? <FaCompress /> : <FaExpand />}
        </button>
        {tokenPBI && embeddedURL && (
          <PowerBIEmbed

            embedConfig={{
              type: "report",
              id: props.idPowerBi,
              embedUrl: `${embeddedURL}&filter=LokDeviceID/FKLokProyecto eq ${project}`,
              accessToken: tokenPBI,
              tokenType: models.TokenType.PowerBIEmbed,
              background: models.BackgroundType.Transparent,
              settings: {
                panes: {
                  pageNavigation: {
                    visible: false,
                  },
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
              },
            }}
            pageName="ReportSection"
            style={{
              backgroundColor: "lightblue",
              border: "1px solid black",
              borderRadius: "5px",
            }}
            cssClassName={isFullscreen ? "powerbi-embed-fullscreen" : "report-style"}
          />
        )}
      </div>


    </>
  );
}
