import React, { useEffect, useState } from 'react'
import "../../App.css";
import "../../Pages/Css/trafico.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";


const TraficoResponsive = (props) => {
    const [moreInfo, setMoreInfo] = useState(null);
    const showMoreInfo = (index) => {
        if (moreInfo === index) {
            setMoreInfo(null); // Ocultar si ya está seleccionado
        } else {
            setMoreInfo(index); // Mostrar información adicional para el índice seleccionado
        }
    };

    useEffect(() => {
        console.log("Responsive Table trafico ==> ", props.contratosList);
        console.log("Responsive Table trafico filtros search ==> ", props.filteredContratos);
    }, [props]);
    return (
        <div className='containerCardsTrafico'>
            {props.filteredContratos !== null ? (
                props.filteredContratos && props.filteredContratos.map((contrato, index) => (
                    <div key={index} className='card ContainerContratoInitial' onClick={() => showMoreInfo(index)}>
                        <div className='infoEmpresaContrato'>
                            <div className='opcionesContrato'>
                                {props.showEmpresa && (
                                    <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                )}
                                <div className="dropdown position-static">
                                    <button
                                        type="button"
                                        className="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                        onClick={props.renderDropdown}
                                    >
                                        <i className="bx bx-dots-vertical position-static"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                        {contrato.FKLokTipoEquipo !== 8 && (
                                            <span
                                                onClick={() => props.abrirModal2(contrato)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalReporteSegimiento"
                                                className="dropdown-item"
                                            >
                                                <i className="bx bxs-map me-1"></i> Tracking Tiempo Real
                                            </span>
                                        )}
                                        <span
                                            onClick={() => props.abrirModalMonitoreo(contrato)}
                                            className="dropdown-item"
                                        >
                                            <i className="bx bxs-map me-1"></i> Monitoreo de tráfico
                                        </span>
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownReportes() }} className="dropdown-item"><i className="bx bxs-report"></i>Reportes <i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenReportes && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.OpenReporteServicio(contrato)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalReporteServicio"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-file me-1"></i> Ver Reporte
                                                    </span>
                                                    {contrato.FKLokTipoEquipo !== 8 && (
                                                        <span
                                                            onClick={() => props.abrirModal(contrato)}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModalReporteDian"
                                                            className="dropdown-item"
                                                        >
                                                            <i className="bx bx-file me-1"></i> Ver Reporte Dian
                                                        </span>

                                                    )}
                                                </div>
                                            )}
                                        </span>

                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownMultimedia() }} className="dropbtn dropdown-item"><i className="bx bx-film"></i>Multimedia <i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenMultimedia && (
                                                <div className="containerSpaceMenu">
                                                    <span onClick={() => props.OpenUploadPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                                                        <i className="bx bx-upload me-1"></i> Subir Fotos
                                                    </span>
                                                    <span onClick={() => props.OpenUploadVideo(contrato)} data-bs-toggle="modal" data-bs-target="#ModalVideo" className="dropdown-item">
                                                        <i className="bx bx-upload me-1"></i> Subir Videos
                                                    </span>
                                                    <span onClick={() => props.OpenGalleryPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                                                        <i className="bx bx-camera me-1"></i> Ver Fotos
                                                    </span>
                                                    <span onClick={() => props.OpenGalleryVideo(contrato)} data-bs-toggle="modal" data-bs-target="#ModalVideoGaleria" className="dropdown-item">
                                                        <i className="bx bx-video me-1"></i> Ver Videos
                                                    </span>
                                                </div>
                                            )}

                                        </span>
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownValitronics() }} className="dropdown-item"><i className="bx bx-check-circle"></i>Valitronics<i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenValitronics && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.OpenComprobante(contrato)}
                                                        data-bs-toggle="modal" data-bs-target="#ModalComprobanteValitronics"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-file me-1"></i> Visualizar Comprobante
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownOpciones() }} className="dropdown-item"><i className="bx bxs-cog"></i>Opciones<i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenOpciones && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.EditContrato(contrato)}
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-edit-alt me-1"></i> Editar Contrato
                                                    </span>
                                                    <span
                                                        onClick={() => props.EditTrayecto(contrato)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalAsignacion"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-edit-alt me-1"></i> Editar Trayecto
                                                    </span>
                                                    {contrato.FKLokTipoEquipo !== 8 && (
                                                        <span
                                                            onClick={() => props.ActualizarContratoDevice(contrato)}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="bx bx-file me-1"></i> Actualizar contrato
                                                        </span>

                                                    )}
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {props.showDispositivo && (
                                <p><span>Dispositivo: </span>{contrato.FKLokDeviceID}</p>
                            )}
                            {props.showPlaca && (
                                <p><span>Placa: </span>{contrato.PlacaTruck}</p>
                            )}
                            {props.showRuta && (
                                <p><span>Ruta: </span>{contrato.DescripcionRuta}</p>
                            )}
                            {props.showEstado && (
                                <p><span>Estado: </span>{contrato.TipoReporte}</p>
                            )}
                            {props.showUbicacionGps && (
                                <p><span>Ubicacion GPS: </span>{contrato.Ciudad}</p>
                            )}
                            {contrato.mostrargeocerca === 1 && (
                                <>
                                    <p><span>Geocerca: </span>{contrato.geocerca}{" "}{`(${contrato.LastReportgeocerca} min)`}</p>
                                </>
                            )}
                            {props.showUbicacionTrafico && (
                                <p><span>Ubicacion Trafico: </span>{contrato.LastReportUbica}</p>
                            )}
                            {props.showTiempo && (
                                <p><span>Tiempo:</span> {contrato.Tiempo}</p>
                            )}
                        </div>
                        <div className='iconsContrato'>
                            <div className='icons'>
                                {props.showBateria && (
                                    <span><img src={`${contrato.icon_bat}`} width="20" height="25" /></span>
                                )}
                                {props.showMoving && (
                                    <span><img src={`${contrato.IconMoving}`} width="25" height="25" /></span>
                                )}
                                {props.showLocked && (
                                    <span><img src={`${contrato.IconLocked}`} width="25" height="25" /></span>
                                )}
                                {props.showBack && (
                                    <span><img src={`${contrato.IconBack}`} width="25" height="25" /></span>
                                )}
                                {props.showDesvio && (
                                    <span><img src={`${contrato.IconDesvio}`} width="25" height="25" /></span>
                                )}
                                {props.showSeguro && (
                                    <span><img src={`${contrato.IconSeguro}`} width="25" height="25" /></span>
                                )}
                                {props.showValitronics && (
                                    <span><img src={`${contrato.IconValitronics}`} width="25" height="25" /></span>
                                )}

                            </div>


                        </div>
                        {(props.collapsableResponsive || moreInfo === index) && (
                            <div className='moreInfoStyles'>
                                <p><span>Contrato: </span>{contrato.ContractID}</p>
                                <p><span>Ref: </span>{contrato.Ref}</p>
                                <p><span>Container: </span>{contrato.ContainerNum}</p>
                                <p><span>Trayecto: </span>{contrato.DescripcionTrayecto}</p>
                                <p><span>Ultimo Contacto: </span>{contrato.LoksysServerTime}</p>
                                <p><span>Observaciones: </span>{contrato.LastReportNota}</p>
                            </div>
                        )}
                    </div>

                ))

            ) : (
                props.contratosList && props.contratosList.map((contrato, index) => (
                    <div key={index} className='card ContainerContratoInitial' onClick={() => showMoreInfo(index)}>
                        <div className='infoEmpresaContrato'>
                            <div className='opcionesContrato'>
                                {props.showEmpresa && (
                                    <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                )}
                                <div className="dropdown position-static">
                                    <button
                                        type="button"
                                        className="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                        onClick={props.renderDropdown}
                                    >
                                        <i className="bx bx-dots-vertical position-static"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                        {contrato.FKLokTipoEquipo !== 8 && (
                                            <span
                                                onClick={() => props.abrirModal2(contrato)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#ModalReporteSegimiento"
                                                className="dropdown-item"
                                            >
                                                <i className="bx bxs-map me-1"></i> Seguimiento Tiempo Real
                                            </span>
                                        )}
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownReportes() }} className="dropdown-item"><i className="bx bxs-report"></i>Reportes <i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenReportes && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.OpenReporteServicio(contrato)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalReporteServicio"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-file me-1"></i> Ver Reporte
                                                    </span>
                                                    {contrato.FKLokTipoEquipo !== 8 && (
                                                        <span
                                                            onClick={() => props.abrirModal(contrato)}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#ModalReporteDian"
                                                            className="dropdown-item"
                                                        >
                                                            <i className="bx bx-file me-1"></i> Ver Reporte Dian
                                                        </span>

                                                    )}
                                                </div>
                                            )}
                                        </span>

                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownMultimedia() }} className="dropbtn dropdown-item"><i className="bx bx-film"></i>Multimedia <i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenMultimedia && (
                                                <div className="containerSpaceMenu">
                                                    <span onClick={() => props.OpenUploadPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalFoto" className="dropdown-item">
                                                        <i className="bx bx-upload me-1"></i> Subir Fotos
                                                    </span>
                                                    <span onClick={() => props.OpenUploadVideo(contrato)} data-bs-toggle="modal" data-bs-target="#ModalVideo" className="dropdown-item">
                                                        <i className="bx bx-upload me-1"></i> Subir Videos
                                                    </span>
                                                    <span onClick={() => props.OpenGalleryPhoto(contrato)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                                                        <i className="bx bx-camera me-1"></i> Ver Fotos
                                                    </span>
                                                    <span onClick={() => props.OpenGalleryVideo(contrato)} data-bs-toggle="modal" data-bs-target="#ModalVideoGaleria" className="dropdown-item">
                                                        <i className="bx bx-video me-1"></i> Ver Videos
                                                    </span>
                                                </div>
                                            )}

                                        </span>
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownValitronics() }} className="dropdown-item"><i className="bx bx-check-circle"></i>Valitronics<i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenValitronics && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.OpenComprobante(contrato)}
                                                        data-bs-toggle="modal" data-bs-target="#ModalComprobanteValitronics"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-file me-1"></i> Visualizar Comprobante
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                        <span className="dropdown">
                                            <button onClick={(e) => { e.stopPropagation(); props.toggleDropdownOpciones() }} className="dropdown-item"><i className="bx bxs-cog"></i>Opciones<i className="bx bx-caret-down"></i></button>
                                            {props.dropdownOpenOpciones && (
                                                <div className="containerSpaceMenu">
                                                    <span
                                                        onClick={() => props.EditContrato(contrato)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#ModalAsignacion"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bx bx-edit-alt me-1"></i> Editar Trayecto
                                                    </span>
                                                    {contrato.FKLokTipoEquipo !== 8 && (
                                                        <span
                                                            onClick={() => props.ActualizarContratoDevice(contrato)}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="bx bx-file me-1"></i> Actualizar contrato
                                                        </span>

                                                    )}
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {props.showDispositivo && (
                                <p><span>Dispositivo: </span>{contrato.FKLokDeviceID}</p>
                            )}
                            {props.showPlaca && (
                                <p><span>Placa: </span>{contrato.PlacaTruck}</p>
                            )}
                            {props.showRuta && (
                                <p><span>Ruta: </span>{contrato.DescripcionRuta}</p>
                            )}
                            {props.showEstado && (
                                <p><span>Estado: </span>{contrato.TipoReporte}</p>
                            )}
                            {props.showUbicacionGps && (
                                <p><span>Ubicacion GPS: </span>{contrato.Ciudad}</p>
                            )}
                            {props.showUbicacionTrafico && (
                                <p><span>Ubicacion Trafico: </span>{contrato.LastReportUbica}</p>
                            )}
                            {props.showTiempo && (
                                <p><span>Tiempo:</span> {contrato.Tiempo}</p>
                            )}
                        </div>
                        <div className='iconsContrato'>
                            <div className='icons'>
                                {props.showBateria && (
                                    <span><img src={`${contrato.icon_bat}`} width="20" height="25" /></span>
                                )}
                                {props.showMoving && (
                                    <span><img src={`${contrato.IconMoving}`} width="25" height="25" /></span>
                                )}
                                {props.showLocked && (
                                    <span><img src={`${contrato.IconLocked}`} width="25" height="25" /></span>
                                )}
                                {props.showBack && (
                                    <span><img src={`${contrato.IconBack}`} width="25" height="25" /></span>
                                )}
                                {props.showDesvio && (
                                    <span><img src={`${contrato.IconDesvio}`} width="25" height="25" /></span>
                                )}
                                {props.showSeguro && (
                                    <span><img src={`${contrato.IconSeguro}`} width="25" height="25" /></span>
                                )}
                                {props.showValitronics && (
                                    <span><img src={`${contrato.IconValitronics}`} width="25" height="25" /></span>
                                )}

                            </div>


                        </div>
                        {(props.collapsableResponsive || moreInfo === index) && (
                            <div className='moreInfoStyles'>
                                <p><span>Contrato: </span>{contrato.ContractID}</p>
                                <p><span>Ref: </span>{contrato.Ref}</p>
                                <p><span>Container: </span>{contrato.ContainerNum}</p>
                                <p><span>Trayecto: </span>{contrato.DescripcionTrayecto}</p>
                                <p><span>Ultimo Contacto: </span>{contrato.LoksysServerTime}</p>
                                <p><span>Observaciones: </span>{contrato.LastReportNota}</p>
                            </div>
                        )}
                    </div>

                ))

            )}
        </div>
    )
}

export default TraficoResponsive
