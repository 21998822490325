import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../Pages/Css/carousel.css';
import ReactImageZoom from 'react-image-zoom';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';

const Gallery = (props) => {
  const [contrato, setContrato] = useState(-1);
  const [fotos, setFotos] = useState([]);
  const [nfotos, setNfotos] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [predictionObject, setPredictionObject] = useState([]);
  const [comparision, setComparision] = useState({});
  const [fotosV, setFotosV] = useState([]);
  const [infoContainerNum, setInfoContainerNum] = useState('');
  const [containerCode, setContainerCode] = useState('');
  const [containerOwner, setContainerOwner] = useState('');
  const [visibleContain, setVisibleContain] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    setContrato(props.contrato);
  }, [props.contrato]);

  useEffect(() => {
    if (currentIndex !== 0) {
      loadAllImageAnalitycs(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (props.contrato !== contrato) {
      const formattedContainerNum = props.infoContrato && props.infoContrato.replace(' ', '');
      (async () => {
        setContrato(props.contrato);
        setFotos([]);
        setFotosV([]);
        setInfoContainerNum(formattedContainerNum);
        await getFotos(props.contrato);
        await getInfoAditionalContrato();
        await getFotosContratoVisualLogistic(props.contrato);
      })();
    }
  }, [props.contrato]);

  useEffect(() => {
    if (fotosV.length > 0) {
      loadAllImageAnalitycs(currentIndex);
    }
  }, [fotosV]);

  const getInfoAditionalContrato = async () => {
    let token = localStorage.getItem('tkn');
    var contrato = { "contrato": 'serv-00372249' };
    try {
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getcontractvisuallogistic', JSON.stringify(contrato), {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        withCredentials: true
      });
      const comparisonResult = response.data.info.assets[0]?.comparison[0]?.result || {};
      setComparision(comparisonResult);
    } catch (err) {
      console.error(err);
    }
  };

  const ocultar = () => {
    const objectBoxes = document.querySelectorAll(".object-box");
    objectBoxes.forEach(objectBox => {
      objectBox.style.display = "none";
    });
  };

  const mostrar = () => {
    const objectBoxes = document.querySelectorAll(".object-box");
    objectBoxes.forEach(objectBox => {
      objectBox.style.display = "block";
    });
  };

  const eliminar = () => {
    const removeElements = (className) => {
      const elements = document.getElementsByClassName(className);
      Array.from(elements).forEach(element => {
        element.parentNode.removeChild(element);
      });
    };
    removeElements('object-box');
    removeElements('object-box2');
    removeElements('object-box3');
  };

  const mostrarInfo = (f) => {
    document.getElementById("infofoto").innerHTML = `${f.Evento}<br/>${infoContainerNum} - ${f.Descripcion}<br/>${f.Latitud} , ${f.Longitud}<br/>${f.Nombreempresa}<br/>${f.Hora}`;
  };

  const handleMouseEnter = (e) => {
    setIsZoomed(true);
    handleMouseMove(e);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const handleMouseMove = (e) => {
    if (isZoomed) {
      const rect = e.target.getBoundingClientRect();
      setMouseX(e.clientX - rect.left);
      setMouseY(e.clientY - rect.top);
    }
  };

  const handleChange = (currentIndex, event) => {
    setCurrentIndex(currentIndex);
    setSelectedItem(currentIndex);
    eliminar();
    const infojson = document.getElementById('infojson');
    infojson.innerHTML = "";
    const f = fotos[currentIndex];
    mostrarInfo(f);
  };

  const getFotosContratoVisualLogistic = async (contrato) => {
    try {
      let servicioContrato = { contrato: contrato };
      let token = localStorage.getItem('tkn');
      if (!token) {
        throw new Error("Token no encontrado en localStorage");
      }
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getfotoscontractvisuallogistic',
        JSON.stringify(servicioContrato),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setFotosV(response.data.info.contract_predictions);
    } catch (error) {
      console.error("Error al consumir el endpoint: ", error);
    }
  };

  const loadAllImageAnalitycs = async (currentIndex) => {
    eliminar();
    const nameFotoActual = fotos && fotos[currentIndex]?.photo;
    const infoByFotoActual = fotosV.find(photo => photo.name === nameFotoActual);
    const f = fotos[currentIndex];
    const photo = document.getElementById(f?.IDPhoto);
    const infojson = document.getElementById('infojson');
    const infoAddJson = document.getElementById('infofoto2');
    infojson.innerHTML = "";
    infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
    const img = new Image();
    img.src = "https://fotos.sfo2.digitaloceanspaces.com/" + f?.photo.substring(0, 10) + "/" + f?.photo;
    img.onload = () => {
      setContainerCode('');
      setContainerOwner('');
      setVisibleContain(false);
      try {
        const predictions = infoByFotoActual.predictions;
        predictions.forEach((prediction, index) => {
          const sanitizedTagName = sanitizeId(prediction.tag_name);
          let cadena = `
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${index}" aria-expanded="true">
                ${prediction.tag_name}
              </button>
            </div>
            <div id="collapse-${index}" class="accordion-collapse collapse show">
              <div class="accordion-body">
                <ul>
          `;
          const boundingBox = prediction.bounding_box;
          const width = boundingBox.right - boundingBox.left;
          const height = boundingBox.bottom - boundingBox.top;
          const imageRect = photo.getBoundingClientRect();
          const offsetX = imageRect.left;
          const offsetY = imageRect.top;
          const offsetW = imageRect.width;
          const offsetH = imageRect.height;
          const diferenciaoriginalW = imageRect.width / img.width;
          const diferenciaoriginalH = imageRect.height / img.height;
          const objectBox = document.createElement("div");
          objectBox.className = "object-box";
          objectBox.title = prediction.tag_name;
          objectBox.id = `box-${index}`;
          objectBox.style.border = "2px solid blue";
          objectBox.style.width = width * diferenciaoriginalW + "px";
          objectBox.style.height = height * diferenciaoriginalH + "px";
          const isResponsive = window.matchMedia("(max-width: 768px)").matches;
          let restanteW, restanteH;
          if (isResponsive) {
            restanteW = ((offsetW) / 2);
            restanteH = (((offsetH - 3) / 2));
          } else {
            restanteW = ((offsetW) / 2) + 250;
            restanteH = (((offsetH - 3) / 2));
          }
          let left, top;
          if (isResponsive) {
            left = (boundingBox.left * diferenciaoriginalW) + 35;
            top = (boundingBox.top * diferenciaoriginalH);
          } else {
            left = (boundingBox.left * diferenciaoriginalW) + restanteW;
            top = (boundingBox.top * diferenciaoriginalH) + restanteH;
          }
          objectBox.style.position = "absolute";
          objectBox.style.left = left + "px";
          objectBox.style.top = top + "px";
          photo.parentNode.appendChild(objectBox);
          const attributes = prediction.attributes;
          let hasAditionalInfo = false;
          let containerCode = '';
          let containerOwner = '';
          attributes.forEach((attribute, attrIndex) => {
            const name = attribute.name;
            const value = attribute.value;
            const content = value;
            if (Array.isArray(content)) {
              content.forEach((contenido, contentIndex) => {
                cadena += "<div class='attribute_class'>" + name + "<ul>";
                cadena += `<li class='content_content' data-box-id='box-${sanitizeId(contenido.content)}'>${contenido.content}</li>`;
                const boundingBox2 = contenido.boundingBox;
                const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
                const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
                const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);
                if (name.includes('Container_Code')) {
                  containerCode = contenido.content.replace(' ', '');
                }
                if (name.includes('Container_Owner')) {
                  containerOwner = contenido.content.replace(' ', '');
                }
                if (containerCode !== '' && containerOwner !== '') {
                  const formattedFusion = containerOwner + containerCode;
                  if (formattedFusion === infoContainerNum) {
                    setVisibleContain(true);
                  } else {
                    setVisibleContain(false);
                  }
                }
                const imageRect2 = photo.getBoundingClientRect();
                const offsetX2 = imageRect.left;
                const offsetY2 = imageRect.top;
                const offsetW2 = imageRect.width;
                const offsetH2 = imageRect.height;
                const diferenciaoriginalW2 = imageRect2.width / img.width;
                const diferenciaoriginalH2 = imageRect2.height / img.height;
                const objectBox2 = document.createElement("div");
                objectBox2.className = "object-box2";
                objectBox2.title = contenido.content;
                objectBox2.id = `box-${sanitizeId(contenido.content)}`;
                objectBox2.style.transformOrigin = "top left";
                objectBox2.style.border = "2px solid #6ABFF4";
                objectBox2.style.transform = `rotate(${angle2}rad)`;
                objectBox2.style.width = width2 * diferenciaoriginalW2 + "px";
                objectBox2.style.height = height2 * diferenciaoriginalH2 + "px";
                const isResponsive2 = window.matchMedia("(max-width: 768px)").matches;
                let restanteW2, restanteH2;
                if (isResponsive2) {
                  restanteW2 = ((offsetW) / 2);
                  restanteH2 = (((offsetH - 3) / 2));
                } else {
                  restanteW2 = ((offsetW) / 2) + 250;
                  restanteH2 = (((offsetH - 3) / 2));
                }
                let left2, top2;
                if (isResponsive2) {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2);
                } else {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2;
                }
                objectBox2.style.position = "absolute";
                objectBox2.style.left = left2 + "px";
                objectBox2.style.top = top2 + "px";
                photo.parentNode.appendChild(objectBox2);
                mostrar();
                attribute.value.forEach(contenido => {
                  if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                    // infoAddJson.innerHTML = "";
                  }
                  hasAditionalInfo = true;
                  attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
                });
              });
            } else if (typeof content === 'string') {
              let iconHTML = '';
              if (name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              const attributeValueHtml = `<div class='attribute_class'><span class='container_span_iconName'>${iconHTML} <span class='color_name_attribute'>${name.toUpperCase()}</span></span>${content}</div>`;
              if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                infoAddJson.innerHTML = "";
              }
              hasAditionalInfo = true;
              infoAddJson.innerHTML += attributeValueHtml;
            } else {
              console.error("attribute.value no es un array:", value);
              cadena += "</ul></div>";
              let iconHTML = '';
              if (attribute.name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              const attributeAddHtml = `
                  <div class='attribute_class'>
                      <span>${iconHTML} <span>${(attribute.name).toUpperCase()}:</span> ${attribute.value}</span>
                    <ul>
                `;
              attributeAddHtml += `</ul></div>`;
            }
          });
          const words = prediction.lines;
          words.forEach((word, wordIndex) => {
            cadena += `<li class='word_content' data-box-id='box-${sanitizeId(word.content)}-${wordIndex}'>${word.content}</li>`;
            const boundingBox2 = word.boundingBox;
            const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
            const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
            const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);
            const imageRect2 = photo.getBoundingClientRect();
            const offsetX2 = imageRect.left;
            const offsetY2 = imageRect.top;
            const offsetW2 = imageRect.width;
            const offsetH2 = imageRect.height;
            const diferenciaoriginalW2 = imageRect2.width / img.width;
            const diferenciaoriginalH2 = imageRect2.height / img.height;
            const objectBox2 = document.createElement("div");
            objectBox2.className = "object-box2";
            objectBox2.title = word.content;
            objectBox2.id = `box-${sanitizeId(word.content)}-${wordIndex}`;
            objectBox2.style.transformOrigin = "top left";
            objectBox2.style.transform = `rotate(${angle2}rad) scale(1)`;
            objectBox2.style.border = "2px solid red";
            objectBox2.style.width = width2 * diferenciaoriginalW2 + "px";
            objectBox2.style.height = height2 * diferenciaoriginalH2 + "px";
            const isResponsive2 = window.matchMedia("(max-width: 768px)").matches;
            let restanteW2, restanteH2;
            if (isResponsive2) {
              restanteW2 = ((offsetW) / 2);
              restanteH2 = (((offsetH - 3) / 2));
            } else {
              restanteW2 = ((offsetW) / 2) + 250;
              restanteH2 = (((offsetH - 3) / 2));
            }
            let left2, top2;
            if (isResponsive2) {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
              top2 = (boundingBox2[1] * diferenciaoriginalH2);
            } else {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2;
              top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2;
            }
            objectBox2.style.position = "absolute";
            objectBox2.style.left = left2 + "px";
            objectBox2.style.top = top2 + "px";
            photo.parentNode.appendChild(objectBox2);
            mostrar();
          });
          if (cadena.includes("<li>")) {
            cadena += "</ul></div>";
          } else {
            cadena.replace("<ul>", "");
            cadena += "</div>";
          }
          infojson.innerHTML += cadena;
        });
        document.querySelectorAll('.word_content, .attribute_content, .content_content').forEach(wordContentActive => {
          wordContentActive.addEventListener('click', function () {
            const clickedWord = this.dataset.boxId;
            document.querySelectorAll('.object-box2').forEach(box => {
              box.style.borderColor = "";
              box.style.animation = "";
            });
            const box = document.getElementById(clickedWord);
            if (box) {
              box.style.borderColor = "green";
              const angle2 = window.getComputedStyle(box).getPropertyValue('transform');
              const pulseAnimation = `
                @keyframes pulseAnimation {
                    0% { transform: ${angle2} scale(1); }
                    50% { transform: ${angle2} scale(1.1); }
                    100% { transform: ${angle2} scale(1); }
                }
            `;
              const style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = pulseAnimation;
              document.head.appendChild(style);
              box.style.animation = "pulseAnimation 1s ease-in-out infinite";
            }
          });
        });
        document.querySelectorAll('.accordion-button').forEach((button, index) => {
          button.classList.add('active');
          const targetId = `box-${index}`;
          button.addEventListener('click', function () {
            const collapseElement = document.getElementById(button.getAttribute('data-bs-target').substring(1));
            const boxId = `box-${index}`;
            collapseElement.addEventListener('hidden.bs.collapse', function () {
              button.classList.add('collapsed');
              button.classList.remove('active');
              resetBoxStyles();
              highlightBox(boxId);
              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
            collapseElement.addEventListener('shown.bs.collapse', function () {
              button.classList.remove('collapsed');
              button.classList.add('active');
              resetBoxStyles();
              highlightBox(boxId);
              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
          });
        });
        document.addEventListener('click', function (event) {
          if (!event.target.classList.contains('accordion-button')) {
            resetBoxStyles();
            hideBackdrop();
          }
        });
        function resetBoxStyles() {
          document.querySelectorAll('.object-box, object-box2').forEach(box => {
            box.style.borderColor = "";
            box.style.borderWidth = "2px";
          });
        }
        function highlightBox(boxId) {
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue";
            box.style.borderWidth = "4px";
            box.style.backgroundColor = 'rgba(0, 0, 255, 0.5)';
          }
        }
        function hideBackdrop(boxId) {
          const masks = document.querySelectorAll('.mask');
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue";
            box.style.borderWidth = "2px";
            box.style.backgroundColor = '';
          }
          masks.forEach(mask => {
            mask.remove();
          });
        }
      } catch (error) {
        console.error("Error al obtener datos JSON:", error);
      }
    };
  };

  const handleClick = async (event) => {
    event.preventDefault();
    eliminar();
    const infojson = document.getElementById('infojson');
    const infoAddJson = document.getElementById('infofoto2');
    infojson.innerHTML = "";
    infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
    const f = fotos[currentIndex];
    document.getElementById("infofoto").innerHTML = `${f.Evento}<br/>${infoContainerNum} - ${f.Descripcion}<br/>${f.Latitud} , ${f.Longitud}<br/>${f.Nombreempresa}<br/>${f.Hora}`;
    const photo = document.getElementById(f.IDPhoto);
    const img = new Image();
    const jsonData = await getFotosjSON(f.photo);
    img.src = "https://fotos.sfo2.digitaloceanspaces.com/" + f.photo.substring(0, 10) + "/" + f.photo;
    img.onload = () => {
      try {
        const predictions = jsonData.predictions;
        predictions.forEach((prediction, index) => {
          const sanitizedTagName = sanitizeId(prediction.tag_name);
          let cadena = `
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${index}" aria-expanded="true">
                ${prediction.tag_name}
              </button>
            </div>
            <div id="collapse-${index}" class="accordion-collapse collapse show">
              <div class="accordion-body">
                <ul>
          `;
          const boundingBox = prediction.bounding_box;
          const width = boundingBox.right - boundingBox.left;
          const height = boundingBox.bottom - boundingBox.top;
          const imageRect = photo.getBoundingClientRect();
          const offsetX = imageRect.left;
          const offsetY = imageRect.top;
          const offsetW = imageRect.width;
          const offsetH = imageRect.height;
          const diferenciaoriginalW = imageRect.width / img.width;
          const diferenciaoriginalH = imageRect.height / img.height;
          const objectBox = document.createElement("div");
          objectBox.className = "object-box";
          objectBox.title = prediction.tag_name;
          objectBox.id = `box-${index}`;
          objectBox.style.border = "2px solid blue";
          objectBox.style.width = width * diferenciaoriginalW + "px";
          objectBox.style.height = height * diferenciaoriginalH + "px";
          const isResponsive = window.matchMedia("(max-width: 768px)").matches;
          let restanteW, restanteH;
          if (isResponsive) {
            restanteW = ((offsetW) / 2);
            restanteH = (((offsetH - 3) / 2));
          } else {
            restanteW = ((offsetW) / 2) + 250;
            restanteH = (((offsetH - 3) / 2));
          }
          let left, top;
          if (isResponsive) {
            left = (boundingBox.left * diferenciaoriginalW) + 35;
            top = (boundingBox.top * diferenciaoriginalH);
          } else {
            left = (boundingBox.left * diferenciaoriginalW) + restanteW;
            top = (boundingBox.top * diferenciaoriginalH) + restanteH;
          }
          objectBox.style.position = "absolute";
          objectBox.style.left = left + "px";
          objectBox.style.top = top + "px";
          photo.parentNode.appendChild(objectBox);
          const attributes = prediction.attributes;
          let hasAditionalInfo = false;
          let containerCode = '';
          let containerOwner = '';
          attributes.forEach((attribute, attrIndex) => {
            const name = attribute.name;
            const value = attribute.value;
            const content = value;
            if (Array.isArray(content)) {
              content.forEach((contenido, contentIndex) => {
                cadena += "<div class='attribute_class'>" + name + "<ul>";
                cadena += `<li class='content_content' data-box-id='box-${sanitizeId(contenido.content)}'>${contenido.content}</li>`;
                const boundingBox2 = contenido.boundingBox;
                const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
                const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
                const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);
                if (name.includes('Container_Code')) {
                  containerCode = contenido.content.replace(' ', '');
                }
                if (name.includes('Container_Owner')) {
                  containerOwner = contenido.content.replace(' ', '');
                }
                if (containerCode !== '' && containerOwner !== '') {
                  const formattedFusion = containerOwner + containerCode;
                  if (formattedFusion === infoContainerNum) {
                    setVisibleContain(true);
                  } else {
                    setVisibleContain(false);
                  }
                }
                const imageRect2 = photo.getBoundingClientRect();
                const offsetX2 = imageRect.left;
                const offsetY2 = imageRect.top;
                const offsetW2 = imageRect.width;
                const offsetH2 = imageRect.height;
                const diferenciaoriginalW2 = imageRect2.width / img.width;
                const diferenciaoriginalH2 = imageRect2.height / img.height;
                const objectBox2 = document.createElement("div");
                objectBox2.className = "object-box2";
                objectBox2.title = contenido.content;
                objectBox2.id = `box-${sanitizeId(contenido.content)}`;
                objectBox2.style.transformOrigin = "top left";
                objectBox2.style.border = "2px solid #6ABFF4";
                objectBox2.style.transform = `rotate(${angle2}rad)`;
                objectBox2.style.width = width2 * diferenciaoriginalW2 + "px";
                objectBox2.style.height = height2 * diferenciaoriginalH2 + "px";
                const isResponsive2 = window.matchMedia("(max-width: 768px)").matches;
                let restanteW2, restanteH2;
                if (isResponsive2) {
                  restanteW2 = ((offsetW) / 2);
                  restanteH2 = (((offsetH - 3) / 2));
                } else {
                  restanteW2 = ((offsetW) / 2) + 250;
                  restanteH2 = (((offsetH - 3) / 2));
                }
                let left2, top2;
                if (isResponsive2) {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2);
                } else {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2;
                }
                objectBox2.style.position = "absolute";
                objectBox2.style.left = left2 + "px";
                objectBox2.style.top = top2 + "px";
                photo.parentNode.appendChild(objectBox2);
                mostrar();
                attribute.value.forEach(contenido => {
                  if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                    // infoAddJson.innerHTML = "";
                  }
                  hasAditionalInfo = true;
                  attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
                });
              });
            } else if (typeof content === 'string') {
              let iconHTML = '';
              if (name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              const attributeValueHtml = `<div class='attribute_class'><span class='container_span_iconName'>${iconHTML} <span class='color_name_attribute'>${name.toUpperCase()}</span></span>${content}</div>`;
              if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                infoAddJson.innerHTML = "";
              }
              hasAditionalInfo = true;
              infoAddJson.innerHTML += attributeValueHtml;
            } else {
              console.error("attribute.value no es un array:", value);
              cadena += "</ul></div>";
              let iconHTML = '';
              if (attribute.name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              const attributeAddHtml = `
                  <div class='attribute_class'>
                      <span>${iconHTML} <span>${(attribute.name).toUpperCase()}:</span> ${attribute.value}</span>
                    <ul>
                `;
              attributeAddHtml += `</ul></div>`;
            }
          });
          const words = prediction.lines;
          words.forEach((word, wordIndex) => {
            cadena += `<li class='word_content' data-box-id='box-${sanitizeId(word.content)}-${wordIndex}'>${word.content}</li>`;
            const boundingBox2 = word.boundingBox;
            const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
            const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
            const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);
            const imageRect2 = photo.getBoundingClientRect();
            const offsetX2 = imageRect.left;
            const offsetY2 = imageRect.top;
            const offsetW2 = imageRect.width;
            const offsetH2 = imageRect.height;
            const diferenciaoriginalW2 = imageRect2.width / img.width;
            const diferenciaoriginalH2 = imageRect2.height / img.height;
            const objectBox2 = document.createElement("div");
            objectBox2.className = "object-box2";
            objectBox2.title = word.content;
            objectBox2.id = `box-${sanitizeId(word.content)}-${wordIndex}`;
            objectBox2.style.transformOrigin = "top left";
            objectBox2.style.transform = `rotate(${angle2}rad) scale(1)`;
            objectBox2.style.border = "2px solid red";
            objectBox2.style.width = width2 * diferenciaoriginalW2 + "px";
            objectBox2.style.height = height2 * diferenciaoriginalH2 + "px";
            const isResponsive2 = window.matchMedia("(max-width: 768px)").matches;
            let restanteW2, restanteH2;
            if (isResponsive2) {
              restanteW2 = ((offsetW) / 2);
              restanteH2 = (((offsetH - 3) / 2));
            } else {
              restanteW2 = ((offsetW) / 2) + 250;
              restanteH2 = (((offsetH - 3) / 2));
            }
            let left2, top2;
            if (isResponsive2) {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
              top2 = (boundingBox2[1] * diferenciaoriginalH2);
            } else {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2;
              top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2;
            }
            objectBox2.style.position = "absolute";
            objectBox2.style.left = left2 + "px";
            objectBox2.style.top = top2 + "px";
            photo.parentNode.appendChild(objectBox2);
            mostrar();
          });
          if (cadena.includes("<li>")) {
            cadena += "</ul></div>";
          } else {
            cadena.replace("<ul>", "");
            cadena += "</div>";
          }
          infojson.innerHTML += cadena;
        });
        document.querySelectorAll('.word_content, .attribute_content, .content_content').forEach(wordContentActive => {
          wordContentActive.addEventListener('click', function () {
            const clickedWord = this.dataset.boxId;
            document.querySelectorAll('.object-box2').forEach(box => {
              box.style.borderColor = "";
              box.style.animation = "";
            });
            const box = document.getElementById(clickedWord);
            if (box) {
              box.style.borderColor = "green";
              const angle2 = window.getComputedStyle(box).getPropertyValue('transform');
              const pulseAnimation = `
                @keyframes pulseAnimation {
                    0% { transform: ${angle2} scale(1); }
                    50% { transform: ${angle2} scale(1.1); }
                    100% { transform: ${angle2} scale(1); }
                }
            `;
              const style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = pulseAnimation;
              document.head.appendChild(style);
              box.style.animation = "pulseAnimation 1s ease-in-out infinite";
            }
          });
        });
        document.querySelectorAll('.accordion-button').forEach((button, index) => {
          button.classList.add('active');
          const targetId = `box-${index}`;
          button.addEventListener('click', function () {
            const collapseElement = document.getElementById(button.getAttribute('data-bs-target').substring(1));
            const boxId = `box-${index}`;
            collapseElement.addEventListener('hidden.bs.collapse', function () {
              button.classList.add('collapsed');
              button.classList.remove('active');
              resetBoxStyles();
              highlightBox(boxId);
              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
            collapseElement.addEventListener('shown.bs.collapse', function () {
              button.classList.remove('collapsed');
              button.classList.add('active');
              resetBoxStyles();
              highlightBox(boxId);
              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
          });
        });
        document.addEventListener('click', function (event) {
          if (!event.target.classList.contains('accordion-button')) {
            resetBoxStyles();
            hideBackdrop();
          }
        });
        function resetBoxStyles() {
          document.querySelectorAll('.object-box, object-box2').forEach(box => {
            box.style.borderColor = "";
            box.style.borderWidth = "2px";
          });
        }
        function highlightBox(boxId) {
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue";
            box.style.borderWidth = "4px";
            box.style.backgroundColor = 'rgba(0, 0, 255, 0.5)';
          }
        }
        function hideBackdrop(boxId) {
          const masks = document.querySelectorAll('.mask');
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue";
            box.style.borderWidth = "2px";
            box.style.backgroundColor = '';
          }
          masks.forEach(mask => {
            mask.remove();
          });
        }
      } catch (error) {
        console.error("Error al obtener datos JSON:", error);
      }
    };
  };

  const loadFillData = (array) => {
    if (array.length > 0) {
      return array.map((data, index) => {
        const f = "https://fotos.sfo2.digitaloceanspaces.com/" + data.photo.substring(0, 10) + "/" + data.photo;
        const id = "f" + data.IDPhoto;
        const props = { width: 400, zoomWidth: 500, zoomPosition: 'original', img: f };
        const zoomedAreaStyle = {
          background: 'white',
          opacity: 0.4,
          position: 'absolute',
          width: 'auto',
          height: '400px',
          top: `${mouseY}px`,
          left: `${mouseX}px`,
          display: isZoomed ? 'block' : 'none',
        };
        const zoomedImageStyle = {
          backgroundImage: `url("${f}")`,
          backgroundRepeat: 'no-repeat',
          display: isZoomed ? 'block' : 'none',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundSize: '700px 800px',
          backgroundPosition: `${-1 * (mouseX - 50)}px ${-1 * (mouseY)}px`,
        };
        const cursorStyle = isZoomed ? { cursor: 'zoom-in' } : {};
        return (
          <div key={index} className="image-container" onMouseDown={handleMouseEnter}
            onMouseUp={handleMouseLeave} onMouseMove={handleMouseMove} style={cursorStyle}>
            <img
              id={data.IDPhoto}
              src={f}
              ref={imageRef}
              style={{ maxHeight: "400px", width: "auto" }}
            />
            <div className="js-image-zoom__zoomed-area" style={zoomedAreaStyle}></div>
            <div className="js-image-zoom__zoomed-image" style={zoomedImageStyle}></div>
          </div>
        )
      });
    } else {
      if (nfotos !== -1) {
        return (
          <div key={-1}>
            <img src="assets/img/Nofoto.jpg" style={{ maxHeight: "400px", width: "auto" }} />
          </div>
        )
      }
    }
  };

  const getFotos = async (contrato) => {
    try {
      setNfotos(-1);
      setCurrentIndex(0);
      setSelectedItem(0);
      const infojson = document.getElementById('infojson');
      infojson.innerHTML = "";
      let img = 'img';
      let data = { "contrato": contrato, "tipo": img };
      const json = JSON.stringify(data);
      if (contrato !== -1) {
        const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getfotoscontrato', json, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
          withCredentials: true
        });
        if (response.data.success) {
          setFotos(response.data.data);
          setNfotos(1);
          if (response.data.data.length > 0) {
            mostrarInfo(response.data.data[0]);
          }
        } else {
          window.history.go(0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFotosjSON = async (nombreimagen) => {
    let token = localStorage.getItem('tkn');
    try {
      var data = { "nombreimagen": nombreimagen };
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getjsonvisuallogistic', JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        withCredentials: true
      });
      return response.data.info;
    } catch (error) {
      console.error(error);
    }
  };

  const renderCustomPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ backgroundColor: 'red', color: 'white' }}
      >
        Prev
      </button>
    );

  const renderCustomNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ backgroundColor: 'blue', color: 'white' }}
      >
        Next
      </button>
    );

  const renderThumbsArrows = ({ direction, onClick }) => (
    <button className={`thumbs-arrow thumbs-${direction}`} onClick={onClick} style={{ color: 'blue' }}>
      {direction === 'prev' ? '<' : '>'}
    </button>
  );

  return (
    <>
      <MDBRow>
        <MDBCol md='6' style={{ margin: '0', padding: '0' }}>
          <div id="prueba">
            <Carousel selectedItem={selectedItem} showArrows={true} showThumbs={true} showStatus={true} showIndicators={false} renderThumbsArrows={renderThumbsArrows} className="custom-carousel" onChange={handleChange}>
              {loadFillData(fotos)}
            </Carousel>
          </div>
        </MDBCol>
        <MDBCol id="panelinfo" md='6' style={{ margin: '0', padding: '0' }}>
          <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>Información</div>
          <div id="infofoto" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', fontWeight: 700, textAlign: 'center' }}></div>
          <div className="d-flex flex-column flex-md-row">
            <MDBCol id="panelinfo2" md='6' style={{ margin: '0', padding: '0' }}>
              <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                Image Analytics
                <img src="/images/reload.png" alt="Icono" style={{ marginLeft: '10px', width: '18px', cursor: 'pointer' }} onClick={handleClick} />
                <i className='bx bxs-trash bx-sm bx-tada-hover' onClick={eliminar}></i>
              </div>
              <div id="infojson" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', height: '300px', overflow: 'auto' }}></div>
            </MDBCol>
            <MDBCol id="panelinfo3" md='6' style={{ margin: '0', padding: '0' }}>
              <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0', overflow: 'auto' }}>In-depth Analysis</div>
              <div id="infofoto2" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', height: '300px', overflowY: 'scroll' }}></div>
            </MDBCol>
          </div>
          {containerOwner !== '' && containerCode !== '' ? (
            visibleContain ? (
              <div style={{ backgroundColor: 'green', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>El contenedor de la imagen coincide con el asignado</div>
            ) : (
              <div style={{ backgroundColor: 'red', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>El contenedor de la imagen no coincide con el asignado</div>
            )
          ) : null}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Gallery;
