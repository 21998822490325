import React from 'react';
import '../Pages/Css/switchButoon.css';

const SwitchButton3 = ({ checked, onChange, type, disabled }) => {
    const toggleSwitch = () => {
        if (!disabled) {
            onChange(!checked); // Notifica al padre del nuevo estado
        }
    };

    return (
        <>
            <button
                type="button"
                className={`ToggleSwitch ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
                onClick={toggleSwitch}
                disabled={disabled} // Deshabilita el botón si `disabled` es true
            >
                <span className="ToggleSwitchInner">
                    <span className="ToggleSwitchHandle" />
                </span>
            </button>
            {type}
        </>
    );
};

export default SwitchButton3;
