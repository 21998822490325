import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import BarraPrincipal from '../Modulos/BarraPrincipal';
import $ from 'jquery';
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';


const Valitronics = ({onClose}) => {
    const [pedido, setPedido] = useState('');
    const [contenedores, setContenedores] = useState([]);
    const [currentContenedor, setCurrentContenedor] = useState('');
    const [nroContenedor, setNroContenedor] = useState('');
    const [digitoVerificacion, setDigitoVerificacion] = useState('');
    const [dates, setDates] = useState({ fechaHoraCita: '' });
    const [loading, setLoading] = useState(false);

    const refs = {
        fechaHoraCita: useRef(null),
    };

    const formatToISO = (fechaHoraCita) => {
        if (!fechaHoraCita) return '';
        const date = new Date(fechaHoraCita.replace(' ', 'T')); // Reemplaza espacio por "T"
        return date.toISOString(); // Devuelve en formato completo: YYYY-MM-DDTHH:mm:ss.sssZ
    };

    const formatContenedoresToString = (contenedores) => {
        if (!contenedores || contenedores.length === 0) return '';
        return contenedores.join(','); // Une los elementos del array con comas
    };


    const cleanIfields = async () => {
        setPedido('');
        setContenedores([]);
        setCurrentContenedor('');
        setNroContenedor('');
        setDigitoVerificacion('');
        setDates({ fechaHoraCita: '' });
    }

    const validateNroContent = (codigo) => {
        const nroContenedorLength = codigo.length;

        console.log("Value nro contenedor: ", codigo);
        console.log("Tamano nro contenedor input: ", nroContenedorLength);

        // Function to calculate the verification digit
        function fCalDig(codigo) {
            const refc = "0123456789A_BCDEFGHIJK_LMNOPQRSTU_VWXYZ";
            let nValor, nTotal = 0, nPow2 = 1;
            if (codigo.length !== 10) return ' ';
            for (let n = 0; n < 10; n++) {
                nValor = refc.indexOf(codigo.charAt(n));
                if (nValor < 0) return ' ';
                nTotal += nValor * nPow2;
                nPow2 *= 2;
            }
            nTotal = nTotal % 11;
            if (nTotal >= 10) nTotal = 0;
            return nTotal.toString();
        }

        // Function to show error using Swal
        function showError(message) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: message,
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        }


        if (nroContenedorLength === 0) {
            setDigitoVerificacion('');
        }

        if (nroContenedorLength === 10) {
            let firstFourAreStrings = true;
            let lastSixAreNumbers = true;

            // Validate that the first 4 characters are letters
            for (let index = 0; index < 4; index++) {
                if (!isNaN(codigo[index])) {
                    firstFourAreStrings = false;
                    break;
                }
            }

            // Validate that the last 6 characters are numbers
            for (let index = 4; index < nroContenedorLength; index++) {
                if (isNaN(codigo[index])) {
                    lastSixAreNumbers = false;
                    break;
                }
            }

            if (firstFourAreStrings) {
                console.log("Nro contenedor inicia con 4 caracteres de tipo string (letras)");
            } else {
                console.log("Nro contenedor no inicia con 4 caracteres de tipo string (letras)");
                showError("El número del contenedor debe tener el siguiente formato 'AAAA123456'");
            }

            if (lastSixAreNumbers) {
                console.log("Nro contenedor termina con 6 caracteres de tipo número");
            } else {
                console.log("Nro contenedor no termina con 6 caracteres de tipo número");
                showError("El número del contenedor debe tener el siguiente formato 'AAAA123456'");
            }

            if (lastSixAreNumbers && firstFourAreStrings) {
                const verificationDigit = fCalDig(codigo);
                console.log("Codigo total: ", codigo);
                console.log("Verification Digit: ", verificationDigit);
                setDigitoVerificacion(verificationDigit);
            }
        }
    };

    const onSubmit = async (data) => {
        console.log(data);
        await axios
            .post(process.env.REACT_APP_SERVER + "/contratos/crearcontratov", JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    console.log(res.data.mensaje);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    cleanIfields();
                    onClose();
                    Swal.fire({
                        icon: "success",
                        title: "Contrato creado con éxito",
                        text: "Contrato cargado con éxito",
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#006699';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });

                }
            }).catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Error al crear el contrato",
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            });
    }

    const handleGuardar = async () => {
        if (!pedido || contenedores.length === 0 || !dates.fechaHoraCita) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Todos los campos y la lista de contenedores deben estar llenos.',
            });
            return;
        }

        setLoading(true);
        const containerFormat = formatContenedoresToString(contenedores);
        let data = {
            ubicacion: pedido,
            listaequipo: containerFormat,
            fechahora: dates.fechaHoraCita
        }

        console.log("Data: ", data);
        await onSubmit(data);
        setLoading(false);
    };

    const handleAddContenedor = () => {
        if (contenedores.length >= 5) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No puedes agregar más de 5 contenedores.',
            });
            return; // No permite agregar más de 5 contenedores
        }

        if (!nroContenedor || nroContenedor.length !== 10) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debe ingresar un número de contenedor válido de 10 caracteres.',
            });
            return;
        }

        if (!digitoVerificacion) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El número de contenedor no tiene un dígito de verificación válido.',
            });
            return;
        }

        const contenedorCompleto = `${nroContenedor}-${digitoVerificacion}`;

        if (contenedores.includes(contenedorCompleto)) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Este número de contenedor ya ha sido agregado.',
            });
            return;
        }

        // Agregar el contenedor validado al array y actualizar currentContenedor
        setContenedores([...contenedores, contenedorCompleto]);
        setCurrentContenedor(contenedorCompleto);
        setNroContenedor('');
        setDigitoVerificacion('');
    };


    const handleRemoveContenedor = (contenedor) => {
        setContenedores(contenedores.filter((item) => item !== contenedor));
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            if (refs.fechaHoraCita.current) {
                const now = new Date();
                const currentDateTime = now.toISOString().slice(0, 16).replace('T', ' ');

                $.datetimepicker.setLocale('en');
                $(refs.fechaHoraCita.current).datetimepicker({
                    format: 'Y-m-d H:i',
                    step: 30,
                    value: currentDateTime,
                    timepicker: true,
                    datepicker: true,
                    maxDate: now,
                    onChangeDateTime: (dp, $input) => {
                        const selectedDate = new Date($input.val());
                        console.log("fHoraCita seleccionada: ", selectedDate);

                        // Validación: Fecha seleccionada no debe ser mayor a la actual
                        if (selectedDate > now) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Fecha inválida',
                                text: 'La fecha/hora seleccionada no puede ser mayor a la actual.',
                                confirmButtonColor: '#006699',
                            });
                            $input.val(''); // Limpia el campo si es inválido
                            setDates((prevState) => ({
                                ...prevState,
                                fechaHoraCita: '', // Resetea el estado
                            }));
                        } else {
                            setDates((prevState) => ({
                                ...prevState,
                                fechaHoraCita: $input.val(), // Actualiza con la fecha válida
                            }));
                        }
                    },
                });
            }
        }, 100); // Retraso ligero para asegurar que el DOM esté completamente cargado

        return () => {
            if (refs.fechaHoraCita.current) {
                $(refs.fechaHoraCita.current).datetimepicker('destroy');
            }
            clearTimeout(timer);
        };
    }, [refs.fechaHoraCita]);


    useEffect(() => {
        console.log("Contenedores: ", contenedores);
    }, [contenedores]);

    useEffect(() => {
        console.log("Nro contenedor: ", nroContenedor);
    }, [nroContenedor]);

    return (
        <>

            {/* <div
                className="layout-wrapper layout-content-navbar"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '80px', // Añade un margen para evitar superposición con el Navbar
                }}
            >
                <div
                    style={{
                        width: '400px',
                        backgroundColor: '#fff',
                       
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <MDBRow>
                        <MDBCol md="12">
                            <label
                                className="fw-bold mb-4 label_trafico"
                                style={{ fontSize: '22px', marginBottom: '20px', display: 'block', textAlign: 'center' }}
                            >
                                Creación de Contratos Valitronics
                            </label>
                        </MDBCol>
                    </MDBRow> */}
            <form>
                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="pedido" style={{ display: 'block', marginBottom: '8px' }}>Pedido:</label>
                    <Input
                        type="text"
                        id="pedido"
                        value={pedido}
                        onChange={(e) => setPedido(e.target.value)}
                        style={{ width: '92%' }}
                    />
                </div>

                {contenedores.length > 0 && (
                    <div style={{ marginBottom: '16px' }}>
                        <label htmlFor="contenedores" style={{ display: 'block', marginBottom: '8px' }}>Lista de Contenedores:</label>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                            {contenedores.map((contenedor, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e0f7fa',
                                        padding: '5px 10px',
                                        borderRadius: '16px',
                                        border: '1px solid #00838f',
                                    }}
                                >
                                    <span style={{ marginRight: '8px' }}>{contenedor}</span>
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveContenedor(contenedor)}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'red',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="contenedor" style={{ display: 'block', marginBottom: '8px' }}>Contenedor:</label>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                        <Input maxLength="10" value={nroContenedor} onChange={(e) => {
                            const value = e.target.value.toUpperCase();
                            setNroContenedor(value);
                            validateNroContent(value);
                        }} style={{ textTransform: 'uppercase' }} />
                        <span style={{ display: 'flex', alignItems: 'center' }}>-</span>
                        <Input id="guiaContenedorNro" value={digitoVerificacion} disabled />

                        <button
                            type="button"
                            style={{
                                marginLeft: '8px',
                                color: 'blue',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={handleAddContenedor}
                        >
                            Agregar
                        </button>
                    </div>
                </div>

                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="fechaInicio" style={{ display: 'block', marginBottom: '8px' }}>Fecha/Hora Inicio:</label>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {/* Aplicar manualmente las clases de Reactstrap */}
                        <input
                            ref={refs.fechaHoraCita}
                            type="text"
                            value={dates.fechaHoraCita || ''}
                            onChange={(e) => setDates({ ...dates, fechaHoraCita: e.target.value })}
                            autoComplete="off"
                            className="form-control" // Clase principal para estilo de Bootstrap
                            style={{
                                width: '100%',
                                border: '1px solid #ced4da',
                                borderRadius: '.25rem',
                                padding: '.375rem .75rem',
                                fontSize: '1rem',
                                lineHeight: '1.5',
                            }}
                        />
                    </div>
                </div>


                <div style={{ textAlign: 'center' }}>
                    <button
                        type="button"
                        onClick={handleGuardar}
                        disabled={!pedido || contenedores.length === 0 || !dates.fechaHoraCita || loading}
                        style={{
                            marginTop: '16px',
                            padding: '10px 20px',
                            backgroundColor: loading ? '#ccc' : '#006699',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: loading ? 'not-allowed' : 'pointer',
                            fontSize: '16px',
                            marginRight: '10px',
                            position: 'relative',
                        }}
                    >
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '8px' }}
                            ></span>
                        ) : null}
                        Guardar
                    </button>

                </div>
            </form>
            {/* </div>
            </div> */}
        </>
    );
};

export default Valitronics;
