import React, { useEffect, useState, useRef } from 'react';
import axios from '../utils/axiosConfig';
import BarraPrincipal from './BarraPrincipal';
import '../Pages/Css/reportes.css';
import { FaExpand } from '@react-icons/all-files/fa/FaExpand';
import { FaCompress } from '@react-icons/all-files/fa/FaCompress';

const ReporteTabla3 = ({ reportId, data }) => {
    const [iframeSrc, setIframeSrc] = useState('');
    const empresa = localStorage.getItem('empresa');
    const proyecto = localStorage.getItem('proyecto');

    const [isFullscreen, setIsFullscreen] = useState(false);
    const cardBodyRef = useRef(null);
    const fullscreenButtonRef = useRef(null);  // Referencia al botón de pantalla completa

    const setTokenCookie = () => {
        const token = localStorage.getItem('tkn');
        if (token) {
            document.cookie = `Authorization=Bearer ${token}; path=/`;
        }
    };

    // Manejar tecla ESC para salir de pantalla completa
    const handleKeyDown = (e) => {
        if (e.key === "Escape" && isFullscreen) {
            toggleFullscreen(); // Cerrar pantalla completa si se presiona ESC
        }
    };

    // Cambiar el estado de fullscreen cuando la pantalla completa cambia
    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));  // Actualiza isFullscreen
        }

        // Detectar cambios en el estado de pantalla completa
        document.addEventListener('fullscreenchange', onFullscreenChange);

        // Cleanup: eliminar el listener cuando el componente se desmonta
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenChange);
        };
    }, []);

    // Hacer toggle de fullscreen
    const toggleFullscreen = () => {
        const element = cardBodyRef.current;

        if (!isFullscreen) {
            // Entrar en modo pantalla completa con la API nativa
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        } else {
            // Salir del modo pantalla completa con la API nativa
            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    };

    const getInfoCharts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SERVER}/reportLooker`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('tkn')}`,
                },
                withCredentials: true,
            });

            if (res.status === 200 && res.data.success) {
                const iframeTag = res.data.data;
                const srcMatch = iframeTag.match(/src="([^"]+)"/);
                if (srcMatch && srcMatch[1]) {
                    const selectedReport = data.find(report => report.Id_PowerBI === reportId);

                    if (selectedReport) {
                        const filters = {};
                        const { BitFiltroEmpresa, BitFiltroProyecto, Id_PowerBI } = selectedReport;

                        if (BitFiltroEmpresa) filters["ds0.param_empresa"] = empresa;
                        if (BitFiltroProyecto) filters["ds0.param_proyecto"] = proyecto;

                        const iframeUrl = `${process.env.REACT_APP_SERVER}${srcMatch[1]}&reporte=${reportId}&filtro=${JSON.stringify(filters)}`;
                        setIframeSrc(iframeUrl);
                    }
                }
            }
        } catch (error) {
            console.error("Error al obtener datos del servidor:", error);
        }
    };

    useEffect(() => {
        setTokenCookie();
        getInfoCharts();
    }, []);

    useEffect(() => {
        getInfoCharts();
    }, [reportId]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <>
            <div ref={cardBodyRef}>
                {/* Mostrar el botón solo si no estamos en pantalla completa */}
                {!isFullscreen && (
                    <button
                        ref={fullscreenButtonRef}
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={toggleFullscreen}
                        style={{ width: "90%", textAlign: "center", margin: "0 auto", alignContent: "center", display: 'flex', justifyContent: 'center' }}
                    >
                        {isFullscreen ? <FaCompress /> : <FaExpand />}
                    </button>
                )}
                {iframeSrc && reportId ? (
                    <iframe
                        src={iframeSrc}
                        width="100%"
                        height={isFullscreen ? "100vh" : "800px"} // Ajustar el iframe en pantalla completa
                        title="Reporte Looker"
                        style={{ border: 'none' }}
                        className={isFullscreen ? "powerbi-embed-fullscreen" : "report-style"}
                    />
                ) : (
                    <p>Cargando...</p>
                )}
            </div>
        </>
    );
};

export default ReporteTabla3;
