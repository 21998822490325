import React, { useEffect, useState } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import {
//     ClientSideRowModelModule,
//     RowSelectionModule,
//     TextFilterModule,
//     NumberFilterModule,
//     ValidationModule,
// } from 'ag-grid-community';
// import { ModuleRegistry } from 'ag-grid-community';
// import { AgCharts } from 'ag-charts-react';
// import axios from '../utils/axiosConfig';


// Registra los módulos necesarios
// ModuleRegistry.registerModules([
//     ClientSideRowModelModule,
//     RowSelectionModule,
//     TextFilterModule,
//     NumberFilterModule,
//     ValidationModule,
// ]);

const ReporteConGraficos = () => {
    // const [rowData, setRowData] = useState([]); // Datos de la tabla
    // const [selectedRows, setSelectedRows] = useState([]); // Filas seleccionadas
    // const [chartOptions, setChartOptions] = useState({
    //     title: { text: 'Gráfico Dinámico', fontSize: 18 },
    //     data: [],
    //     series: [],
    //     legend: { position: 'bottom' },
    // });

    // const [chartType, setChartType] = useState('bar'); // Tipo de gráfico
    // const [filterKey, setFilterKey] = useState('NombreEmpresa'); // Filtro para el gráfico

    // const getInfoCharts = async () => {
    //     try {
    //         const res = await axios.get(`${process.env.REACT_APP_SERVER}/operaciones/getcontratostrafico`, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('tkn')}`,
    //             },
    //             withCredentials: true,
    //         });

    //         if (res.status === 200 && res.data.success) {
    //             console.log("Datos obtenidos:", res.data.data);
    //             setRowData(res.data.data || []); // Establece los datos de la tabla
    //         } else {
    //             console.error("Error en la respuesta del servidor:", res.status, res.data);
    //         }
    //     } catch (error) {
    //         console.error("Error al obtener datos del servidor:", error);
    //     }
    // };

    // useEffect(() => {
    //     getInfoCharts();
    // }, []);

    // const handleRowSelection = (params) => {
    //     const selected = params.api.getSelectedRows(); // Obtiene las filas seleccionadas
    //     setSelectedRows(selected);

    //     updateChartOptions(selected); // Actualiza los gráficos con las filas seleccionadas
    // };

    // const updateChartOptions = (data) => {
    //     setChartOptions({
    //         title: { text: `Gráfico de ${filterKey}`, fontSize: 18 },
    //         data,
    //         series: [
    //             {
    //                 type: chartType,
    //                 xKey: filterKey, // Campo dinámico para el eje X
    //                 yKey: 'trayecto', // Campo dinámico para el eje Y
    //                 yName: 'Trayecto',
    //             },
    //         ],
    //         legend: { position: 'bottom' },
    //     });
    // };

    // const handleChartTypeChange = (e) => {
    //     setChartType(e.target.value);
    //     updateChartOptions(selectedRows); // Actualiza el gráfico al cambiar el tipo
    // };

    // const handleFilterKeyChange = (e) => {
    //     setFilterKey(e.target.value);
    //     updateChartOptions(selectedRows); // Actualiza el gráfico al cambiar el filtro
    // };

    // const columnDefs = [
    //     { headerName: 'Empresa', field: 'NombreEmpresa', filter: 'agTextColumnFilter', checkboxSelection: true },
    //     { headerName: 'Contrato', field: 'ContractID', filter: 'agTextColumnFilter' },
    //     { headerName: 'Ruta', field: 'DescripcionRuta', filter: 'agTextColumnFilter' },
    //     { headerName: 'Trayecto', field: 'trayecto', filter: 'agNumberColumnFilter' },
    // ];

    return (
        <div>
            hola
        </div>
    );
};

export default ReporteConGraficos;
